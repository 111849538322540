import { graphql } from "gatsby";
import ProjectList from "../../../../src/components/project-list";
import ProjectTabs from "../../../../src/components/project-tabs";
import "../../../../src/styles/homepage.css";
import "../../../../src/styles/project.css";
import * as React from 'react';
export default {
  graphql,
  ProjectList,
  ProjectTabs,
  React
};